<div class="first">
  <div *ngIf="isLoaded">
    <h1>Consentimiento para ser miembro</h1>
    <hr>
    <p *ngIf="consent[0].paragraph1 !== ''" class="first-p">
      {{consent[0].paragraph1}}
    </p>
    <p *ngIf="consent[0].paragraph2 !== ''" class="first-p">
      {{consent[0].paragraph2}}

    </p>
    <p *ngIf="consent[0].paragraph3 !== ''" class="first-p">
      {{consent[0].paragraph3}}

    </p>
    <p *ngIf="consent[0].paragraph4 !== ''" class="first-p">
      {{consent[0].paragraph4}}

    </p>
    <p *ngIf="consent[0].paragraph5 !== ''" class="first-p">
      {{consent[0].paragraph5}}

    </p>
    <p *ngIf="consent[0].paragraph6 !== ''" class="first-p">
      {{consent[0].paragraph6}}

    </p>
    <p *ngIf="consent[0].paragraph7 !== ''" class="first-p">
      {{consent[0].paragraph7}}

    </p>
    <p *ngIf="consent[0].paragraph8 !== ''" class="first-p">
      {{consent[0].paragraph8}}

    </p>
    <p *ngIf="consent[0].paragraph9 !== ''" class="first-p">
      {{consent[0].paragraph9}}

    </p>
    <p *ngIf="consent[0].paragraph10 !== ''" class="first-p">
      {{consent[0].paragraph10}}

    </p>
    <p *ngIf="consent[0].paragraph11 !== ''" class="first-p">
      {{consent[0].paragraph11}}

    </p>
    <p *ngIf="consent[0].paragraph12 !== ''" class="first-p">
      {{consent[0].paragraph12}}

    </p>
    <p *ngIf="consent[0].paragraph13 !== ''" class="first-p">
      {{consent[0].paragraph13}}

    </p>
    <p *ngIf="consent[0].paragraph14 !== ''" class="first-p">
      {{consent[0].paragraph14}}

    </p>
    <p *ngIf="consent[0].paragraph15 !== ''" class="first-p">
      {{consent[0].paragraph15}}

    </p>
    <p *ngIf="consent[0].paragraph16 !== ''" class="first-p">
      {{consent[0].paragraph16}}

    </p>
    <p *ngIf="consent[0].paragraph17 !== ''" class="first-p">
      {{consent[0].paragraph17}}

    </p>
    <p *ngIf="consent[0].paragraph18 !== ''" class="first-p">
      {{consent[0].paragraph18}}

    </p>
    <p *ngIf="consent[0].paragraph19 !== ''" class="first-p">
      {{consent[0].paragraph19}}

    </p>
    <p *ngIf="consent[0].paragraph20 !== ''" class="first-p">
      {{consent[0].paragraph20}}

    </p>

  </div>
</div>