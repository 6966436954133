import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EmailService } from 'src/app/_services/email.service';
import { UserData, UsersService } from 'src/app/_services/users.service';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent {
  isChecked: boolean = false;
  isInfo: boolean = false;
  isEdit: boolean = false;
  isSpinner: boolean = false;
  isSubmited: boolean = false;

  userSelected!: UserData;

  userData: UserData[] = [];

  displayedColumns = ['name', 'email', 'phone', 'validate', 'action'];
  dataSource!: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private userService: UsersService,
    private emailService: EmailService
  ) {}

  async ngOnInit(): Promise<void> {
    (await this.userService.getUsers()).subscribe((data) => {
      this.userData = data;
      this.dataSource = new MatTableDataSource(this.userData);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  async remove(element: any): Promise<void> {
    Swal.fire({
      title: '¿Seguro que quieres eliminar este usuario?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      confirmButtonColor: '#011d35',
      denyButtonText: 'No',
      denyButtonColor: '#80a2d0',
      customClass: {
        actions: 'my-actions',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.userData = this.userData.filter(({ id }) => id !== element.id);
        await this.userService.deleteUser(element.id);

        this.dataSource = new MatTableDataSource(this.userData);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  async goBack(): Promise<void> {
    document.getElementById('paginator')!.style.display = 'block';
    this.isInfo = false;
    this.isEdit = false;
  }

  async openUser(element: any): Promise<void> {
    document.getElementById('paginator')!.style.display = 'none';
    this.isInfo = true;
    this.userSelected = element;
    this.isChecked = this.userSelected.validate === 1 ? true : false;
  }

  toggle(): void {
    this.isChecked = !this.isChecked;
  }

  async updateValidate(): Promise<void> {
    this.userSelected.validate = this.isChecked ? 1 : 0;

    this.isSpinner = true;
    this.userData = this.userData.map((data) => {
      return data.id === this.userSelected.id
        ? {
            id: data.id,
            name: data.name,
            email: data.email,
            phone: data.phone,
            country: data.country,
            linkedin: data.linkedin,
            licenseType: data.licenseType,
            licenseNumber: data.licenseNumber,
            licenseCustom: data.licenseCustom,
            n_id: data.n_id,
            business: data.business,
            password: data.password,
            validate: this.userSelected.validate,
            lostPassword: data.lostPassword,
          }
        : data;
    });
    this.dataSource = new MatTableDataSource(this.userData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    (
      await this.userService.changeUserValidation(this.userSelected.id)
    ).subscribe(async (data) => {
      if (this.userSelected.validate) {
        (
          await this.emailService.validateUserMail(this.userSelected.email)
        ).subscribe((data) => {
          this.isSpinner = false;
          this.isSubmited = true;
          setTimeout(() => {
            this.isSubmited = false;
            this.goBack();
          }, 2000);
        });
      } else {
        this.isSpinner = false;
        this.isSubmited = true;
        setTimeout(() => {
          this.isSubmited = false;
          this.goBack();
        }, 2000);
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async resendRegisterMail(): Promise<void> {
    this.isSpinner = true;
    (await this.emailService.registerMail(this.userSelected.email)).subscribe(
      (data) => {
        this.isSpinner = false;
        if (data === 'OK') {
          Swal.fire({
            title: '¡Mensaje enviado correctamente!',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#011d35',
          }).then(() => {
            // console.log('ok');
          });
        } else {
          // Swal.fire({
          //   title: '¡Se ha producido un error!',
          //   text: 'Inténtelo de nuevo más tarde.',
          //   icon: 'error',
          //   confirmButtonText: 'OK',
          //   confirmButtonColor: '#011d35',
          // }).then(() => {
          //   this.formGroup.reset();
          // });
        }
      }
    );
  }

  async sendValidateMail(): Promise<void> {
    this.isSpinner = true;
    (
      await this.emailService.validateUserMail(this.userSelected.email)
    ).subscribe((data) => {
      this.isSpinner = false;
      if (data === 'OK') {
        Swal.fire({
          title: '¡Mensaje enviado correctamente!',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#011d35',
        }).then(() => {
          // console.log('ok');
        });
      } else {
        // Swal.fire({
        //   title: '¡Se ha producido un error!',
        //   text: 'Inténtelo de nuevo más tarde.',
        //   icon: 'error',
        //   confirmButtonText: 'OK',
        //   confirmButtonColor: '#011d35',
        // }).then(() => {
        //   this.formGroup.reset();
        // });
      }
    });
  }
}
