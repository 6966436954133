<div class="form">
  <mat-card class="bigOne">
    <br /><br />
    <mat-card-title>DATOS DEL PERFIL</mat-card-title>
    <br />
    <br />
    <!-- <mat-icon matSuffix>accessibility</mat-icon> -->

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form-input">
      <div fxLayout="row wrap">
        <div class="service-card" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
          <mat-card-content>
            <p>
              <mat-form-field appearance="outline" [style.width.px]="300">
                <input type="text" matInput placeholder="Nombre *" formControlName="name" required />
                <mat-icon matSuffix>face</mat-icon>
                <mat-error *ngIf="formGroup.get('name')?.errors && formGroup.get('name')?.errors?.['required']">Nombre
                  obligatorio.</mat-error>
                <mat-error *ngIf="formGroup.get('name')?.errors && formGroup.get('name')?.errors?.['pattern']">Name
                  pattern</mat-error>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline" [style.width.px]="300">
                <input type="text" matInput placeholder="Email *" formControlName="email" required />
                <mat-icon matSuffix>email</mat-icon>
                <mat-error *ngIf="formGroup.get('email')?.errors && formGroup.get('email')?.errors?.['required']">Email
                  obligatorio.</mat-error>
                <mat-error *ngIf="formGroup.get('email')?.errors && formGroup.get('email')?.errors?.['email']">Email
                  format.</mat-error>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline" [style.width.px]="300">
                <input type="number" matInput placeholder="Teléfono móvil *" formControlName="phone" required />
                <mat-icon matSuffix>phone</mat-icon>
                <mat-error
                  *ngIf="formGroup.get('phone')?.errors && formGroup.get('phone')?.errors?.['required']">Teléfono
                  obligatorio.</mat-error>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline" [style.width.px]="300">
                <input type="password" id="pass" matInput placeholder="Contraseña *" formControlName="password"
                  required />
                <mat-icon matSuffix>password</mat-icon>
                <mat-error
                  *ngIf="formGroup.get('password')?.errors && formGroup.get('password')?.errors?.['required']">Contraseña
                  obligatoria.</mat-error>
                <mat-error
                  *ngIf="formGroup.get('password')?.errors && formGroup.get('password')?.errors?.['minlength']">Tamaño
                  minimo de 8 caracteres.</mat-error>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline" [style.width.px]="300">
                <input type="password" matInput placeholder="Confirma contraseña *" formControlName="repeatPassword"
                  id="repeatPassword" required />
                <mat-icon matSuffix>password</mat-icon>
                <mat-error
                  *ngIf="formGroup.get('repeatPassword')?.errors && formGroup.get('repeatPassword')?.errors?.['required']">Confirmar
                  contraseña obligatoria.</mat-error>
                <mat-error
                  *ngIf="formGroup.get('repeatPassword')?.errors && formGroup.get('repeatPassword')?.errors?.['minlength']">Tamaño
                  minimo de 8 caracteres.</mat-error>
                <mat-error
                  *ngIf="formGroup.get('repeatPassword')?.errors && !formGroup.get('repeatPassword')?.errors?.['minlength'] && formGroup.get('repeatPassword')?.errors?.['notSame']">Las
                  contraseñas no coinciden</mat-error>
              </mat-form-field>
            </p>
          </mat-card-content>
        </div>
        <div class="service-card" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
          <mat-card-content class="this-mat">
            <p>
              <mat-form-field appearance="outline" [style.width.px]="300">
                <input type="text" matInput placeholder="Empresa en la que trabajas" formControlName="company" />
                <mat-icon matSuffix>business</mat-icon>
                <!-- <mat-error *ngIf="formGroup.get('company')?.errors && formGroup.get('company')?.errors?.['required']">Nombre obligatorio.</mat-error> -->
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline" [style.width.px]="300">
                <input type="text" matInput placeholder="País de residencia" formControlName="country" />
                <mat-icon matSuffix>explore</mat-icon>
                <!-- <mat-error *ngIf="formGroup.get('country')?.errors && formGroup.get('country')?.errors?.['required']">Country is required.</mat-error> -->
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline" [style.width.px]="300">
                <input type="text" matInput placeholder="Linkedin" formControlName="linkedin" />
                <!-- <mat-error *ngIf="formGroup.get('country')?.errors && formGroup.get('country')?.errors?.['required']">Country is required.</mat-error> -->
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline" [style.width.px]="300">
                <mat-label>Tipo de Licencia</mat-label>
                <mat-select class="select-custom" [(ngModel)]="selectedValue" name="license" formControlName="license"
                  required multiple>
                  <mat-option *ngFor="let license of licenseType" [value]="license.value"
                    (onSelectionChange)="onSelectionChange($event)">
                    {{ license.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="formGroup.get('license')?.errors && formGroup.get('license')?.errors?.['required']">Licencia
                  obligatoria.</mat-error>
              </mat-form-field>
            </p>

            <p *ngIf="otherDiv">
              <mat-form-field appearance="outline" [style.width.px]="300">
                <input type="text" matInput placeholder="Especifica otro tipo de licencia"
                  formControlName="licenceCustom" />
                <!-- <mat-error *ngIf="formGroup.get('licenceCustom')?.errors && formGroup.get('licenceCustom')?.errors?.['required']">Número de licencia obligatorio.</mat-error> -->
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline" [style.width.px]="300">
                <input id="num-lic" type="text" matInput placeholder="Número de licencia"
                  formControlName="licenceNumber" />
                <mat-error
                  *ngIf="formGroup.get('licenceNumber')?.errors && formGroup.get('licenceNumber')?.errors?.['required']">Número
                  de licencia obligatorio.</mat-error>
              </mat-form-field>
            </p>
          </mat-card-content>
        </div>
      </div>
      <br /><br /><br />
      <p class="checkbox">
        <mat-checkbox class="first-check" color="primary" formControlName="conditions" required>
          Acepto
          <a class="links" (click)="conditionClick()">Terminos y Condiciones</a>
          *
        </mat-checkbox>
        <br />
        <mat-checkbox class="second-check" color="primary" formControlName="privacy" required>
          Acepto
          <a class="links" (click)="privacyClick()">Política de Privacidad</a> *
        </mat-checkbox>
      </p>
      <br />

      <div class="button" *ngIf="!isSpinner">
        <button class="send" type="submit" mat-button color="success">
          Registrarse
        </button>
      </div>
      <div class="spinner" *ngIf="isSpinner">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
      <br />

    </form>
  </mat-card>
</div>