import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Votation, VotationService } from 'src/app/_services/votation.service';

@Component({
  selector: 'app-votations',
  templateUrl: './votations.component.html',
  styleUrls: ['./votations.component.css'],
})
export class VotationsComponent {
  displayedColumns = [
    'title',
    'option1',
    'option2',
    'result1',
    'result2',
    'show',
    'action',
  ];
  isAdd: boolean = false;
  isEdit: boolean = false;
  isSpinner: boolean = false;
  isSubmited: boolean = false;
  formGroup!: UntypedFormGroup;
  dataSource!: MatTableDataSource<Votation>;

  votations: Votation[] = [];
  votationToEdit!: Votation;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private votationsService: VotationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSubmited = false;

    (await this.votationsService.getVotation()).subscribe((data) => {
      const dataCast = data;
      this.votations = dataCast.map((el) => {
        return {
          id: el.id,
          title: el.title,
          option1: el.option1,
          option2: el.option2,
          result1: el.result1,
          result2: el.result2,
          url1: el.url1,
          url2: el.url2,
          show: el.show,
          totalVotes: el.totalVotes,
        };
      });
      this.dataSource = new MatTableDataSource(this.votations);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      option1: ['', Validators.required],
      option2: ['', Validators.required],
      show: [false],
    });
  }

  ngAfterViewInit() {}

  openAdd(): void {
    document.getElementById('paginator')!.style.display = 'none';
    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      option1: ['', Validators.required],
      option2: ['', Validators.required],
      url1: ['', Validators.required],
      url2: ['', Validators.required],
      show: [false],
    });
    this.isSubmited = false;
    this.isAdd = true;
  }

  openEdit(element: any): void {
    document.getElementById('paginator')!.style.display = 'none';
    this.votationToEdit = element;
    this.formGroup = this.formBuilder.group({
      title: [element.title, Validators.required],
      option1: [element.option1, Validators.required],
      option2: [element.option2, Validators.required],
      url1: [element.url1, Validators.required],
      url2: [element.url2, Validators.required],
      show: [element.show],
    });
    this.isEdit = true;
  }

  async remove(element: any): Promise<void> {
    this.votations = this.votations.filter(({ id }) => id != element.id);
    await this.votationsService.deleteVotation(element.id);
    this.dataSource = new MatTableDataSource(this.votations);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  goBack(): void {
    document.getElementById('paginator')!.style.display = 'block';
    this.isSubmited = false;
    this.isAdd = false;
    this.isEdit = false;
    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      option1: ['', Validators.required],
      option2: ['', Validators.required],
      url1: ['', Validators.required],
      url2: ['', Validators.required],
      show: [false],
    });
  }

  addVotation(input: Votation): void {
    this.votations.push(input);
    this.dataSource = new MatTableDataSource(this.votations);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.goBack();
  }

  editVotation(input: Votation): void {
    this.votations = this.votations.map((data) => {
      return data.id === this.votationToEdit.id
        ? {
            id: this.votationToEdit.id,
            title: input.title,
            option1: input.option1,
            option2: input.option2,
            url1: input.url1,
            url2: input.url2,
            result1: this.votationToEdit.result1,
            result2: this.votationToEdit.result2,
            show: input.show,
            totalVotes: this.votationToEdit.totalVotes,
          }
        : data;
    });
    this.dataSource = new MatTableDataSource(this.votations);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.goBack();
  }

  async onSubmit(input: any): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      if (this.isAdd) {
        (await this.votationsService.setVotation(input)).subscribe((data) => {
          const dataCast = {
            id: data.id,
            url1 : data.url1,
            url2: data.url2,
            title: data.title,
            option1: data.option1,
            option2: data.option2,
            result1: data.result1,
            result2: data.result2,
            show: data.show,
            totalVotes: data.totalVotes,
          };
          this.isSpinner = false;
          this.isSubmited = true;
          setTimeout(() => {
            this.isSubmited = false;
            this.addVotation(dataCast);
          }, 2000);
        });
      }
      if (this.isEdit) {
        (
          await this.votationsService.editVotation(
            {
              ...input,
              result1: this.votationToEdit.result1,
              result2: this.votationToEdit.result2,
              totalVotes: this.votationToEdit.totalVotes,
            },
            this.votationToEdit.id
          )
        ).subscribe((data) => {
          const dataCast = {
            id: data.id,
            title: data.title,
            url1 : data.url1,
            url2: data.url2,
            option1: data.option1,
            option2: data.option2,
            result1: data.result1,
            result2: data.result2,
            show: data.show,
            totalVotes: data.totalVotes,
          };
          this.isSpinner = false;
          this.isSubmited = true;
          setTimeout(() => {
            this.isSubmited = false;
            this.editVotation(dataCast);
          }, 2000);
        });
      }
    }
  }
}
