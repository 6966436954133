import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface Votation {
  
  id: number;
  title: string;
  option1: string;
  option2: string;
  result1: number;
  result2: number;
  show: number;
  totalVotes: any;
  url2: string;
  url1: string;
}

export interface ResultVotation {
  id: number;
  idVotation: number;
  idUser: number;
  optionSelected: number;
}

@Injectable({
  providedIn: 'root',
})
export class VotationService {
  constructor(private http: HttpClient) {}

  async getVotation(): Promise<Observable<Votation[]>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = environment.apiBasePath + 'votation/getVotations';
    return this.http.get<Votation[]>(path, {headers});
  }

  async setVotation(data: any): Promise<Observable<Votation>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    data.show = data.show ? 1 : 0;
    const body = { ...data, result1: 0, result2: 0, totalVotes: 0 };
    const path = environment.apiBasePath + 'votation/addVotation';
    return this.http.post<Votation>(path, body, {headers});
  }

  async editVotation(data: any, id: number): Promise<Observable<Votation>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    data.show = data.show ? 1 : 0;
    const body = { ...data, id: id };
    const path = environment.apiBasePath + 'votation/editVotation';
    return this.http.put<Votation>(path, body, {headers});
  }

  async updateVotation(data: any, id: number): Promise<Observable<Votation>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    data.show = data.show ? 1 : 0;
    const body = { ...data, id: id };
    const path = environment.apiBasePath + 'votation/updateVotation';
    return this.http.put<Votation>(path, body, {headers});
  }

  async deleteVotation(id: number) {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = `${environment.apiBasePath}votation/deleteVotation/${id}`;
    const result = this.http.delete<Votation>(path, {headers});
    result.subscribe((response) => {
      // console.log('response', response);
    });
  }

  async getResultsVotation(id: number): Promise<Observable<ResultVotation[]>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = `${environment.apiBasePath}resultsVotation/getResultsVotations/${id}`;
    return this.http.get<ResultVotation[]>(path, {headers});
  }

  async updateResultsVotation(vote: any): Promise<Observable<ResultVotation>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = environment.apiBasePath + 'resultsVotation/addResultVotation';
    return this.http.post<ResultVotation>(path, vote, {headers});
  }
}
