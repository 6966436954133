import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface NavTitles {
  id: number;
  title1: string;
  title2: string;
  title3: string;
  title4: string;
  title5: string;
  title6: string;
  title7: string;
  title8: string;
  title9: string;
  title10: string;
  title11: string;
}

export interface About {
  id: number;
  service1: string;
  service2: string;
  service3: string;
  service4: string;
  paragraph1: string;
  paragraph2: string;
}

export interface Contact {
  id: number;
  phone: string;
  email: string;
}

export interface Faqs {
  id: number;
  q1: string;
  a1: string;
  q2: string;
  a2: string;
  q3: string;
  a3: string;
  q4: string;
  a4: string;
}

export interface ExternalLinks {
  id: number;
  link1: string;
  link2: string;
  link3: string;
}

export interface Benefits {
  id: number;
  paragraph1: string;
  paragraph2: string;
  item1: string;
  item2: string;
  item3: string;
  item4: string;
  item5: string;
}

export interface PrivacyConsent {
  id: number;
  paragraph1: string;
  paragraph2: string;
  paragraph3: string;
  paragraph4: string;
  paragraph5: string;
  paragraph6: string;
  paragraph7: string;
  paragraph8: string;
  paragraph9: string;
  paragraph10: string;
  paragraph11: string;
  paragraph12: string;
  paragraph13: string;
  paragraph14: string;
  paragraph15: string;
  paragraph16: string;
  paragraph17: string;
  paragraph18: string;
  paragraph19: string;
  paragraph20: string;
}

@Injectable({
  providedIn: 'root',
})
export class GestionService {
  constructor(private http: HttpClient) {}

  // BENEFITS
  async getBenefits() {
    const path = environment.apiBasePath + 'benefits/getBenefits';
    return this.http.get<Benefits[]>(path);
  }

  async setBenefits(data: any): Promise<Observable<Benefits>> {
    const headers: Record<string, string> = {
      Authorization: sessionStorage.getItem('token')!,
    };
    const body = data;
    const path = environment.apiBasePath + 'benefits/setBenefits';
    const result = this.http.put<Benefits>(path, body, { headers });
    result.subscribe((response) => {
      // console.log('response', response);
    });
    return result;
  }

  // NAV
  async getNavTitles() {
    const path = environment.apiBasePath + 'navbar/getNavTitles';
    return this.http.get<NavTitles[]>(path);
  }

  async setNavTitles(data: any): Promise<Observable<NavTitles>> {
    const headers: Record<string, string> = {
      Authorization: sessionStorage.getItem('token')!,
    };
    const body = data;
    const path = environment.apiBasePath + 'navbar/setNavTitles';
    const result = this.http.put<NavTitles>(path, body, { headers });
    result.subscribe((response) => {
      // console.log('response', response);
    });
    return result;
  }

  // CONTACT
  async getContact() {
    const path = environment.apiBasePath + 'contact/getContact';
    return this.http.get<Contact[]>(path);
  }

  async setContact(data: any) {
    const headers: Record<string, string> = {
      Authorization: sessionStorage.getItem('token')!,
    };
    const body = data;
    const path = environment.apiBasePath + 'contact/setContact';
    const result = this.http.put<Contact>(path, body, { headers });
    result.subscribe((response) => {
      // console.log('response', response);
    });
    return result;
  }

  // FAQS
  async getFaqs() {
    const path = environment.apiBasePath + 'faqs/getFaqs';
    return this.http.get<Faqs[]>(path);
  }

  async setFaqs(data: any) {
    const headers: Record<string, string> = {
      Authorization: sessionStorage.getItem('token')!,
    };
    const body = data;
    const path = environment.apiBasePath + 'faqs/setFaqs';
    const result = this.http.put<Faqs>(path, body, { headers });
    result.subscribe((response) => {
      // console.log('response', response);
    });
    return result;
  }

  // EXTERNAL LINKS
  async getExternalLinks() {
    const path = environment.apiBasePath + 'eLink/getExternalLinks';
    return this.http.get<ExternalLinks[]>(path);
  }

  async setExternalLinks(data: any) {
    const headers: Record<string, string> = {
      Authorization: sessionStorage.getItem('token')!,
    };
    const body = data;
    const path = environment.apiBasePath + 'eLink/setExternalLinks';
    const result = this.http.put<ExternalLinks>(path, body, { headers });
    result.subscribe((response) => {
      // console.log('response', response);
    });
    return result;
  }

  // ABOUT
  async getAbout() {
    const path = environment.apiBasePath + 'about/getAbout';
    return this.http.get<About[]>(path);
  }

  async setAbout(data: any): Promise<Observable<About>> {
    const headers: Record<string, string> = {
      Authorization: sessionStorage.getItem('token')!,
    };
    const body = data;
    const path = environment.apiBasePath + 'about/setAbout';
    const result = this.http.put<About>(path, body, { headers });
    result.subscribe((response) => {
      // console.log('response', response);
    });
    return result;
  }

  // CONSENT
  async getConsent() {
    const path = environment.apiBasePath + 'consent/getConsent';
    return this.http.get<PrivacyConsent[]>(path);
  }

  async setConsent(data: any): Promise<Observable<string>> {
    const headers: Record<string, string> = {
      Authorization: sessionStorage.getItem('token')!,
    };
    const body = data;
    const path = environment.apiBasePath + 'consent/setConsent';
    return this.http.put<string>(path, body, { headers });
  }

  // PRIVACY
  async getPrivacy() {
    const path = environment.apiBasePath + 'privacy/getPrivacy';
    return this.http.get<PrivacyConsent[]>(path);
  }

  async setPrivacy(data: any): Promise<Observable<string>> {
    const headers: Record<string, string> = {
      Authorization: sessionStorage.getItem('token')!,
    };
    const body = data;
    const path = environment.apiBasePath + 'privacy/setPrivacy';
    return this.http.put<string>(path, body, { headers });
  }
}
