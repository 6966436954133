import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  GestionService,
  PrivacyConsent,
} from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-form-privacy',
  templateUrl: './form-privacy.component.html',
  styleUrls: ['./form-privacy.component.css'],
})
export class FormPrivacyComponent implements OnInit {
  isSubmited: boolean = false;
  isSpinner: boolean = false;
  isLoaded: boolean = false;
  formGroup!: UntypedFormGroup;
  formValue: PrivacyConsent[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private gestionService: GestionService
  ) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getPrivacy()).subscribe((data) => {
      this.formValue = data;

      this.formGroup = this.formBuilder.group({
        paragraph1: [this.formValue[0].paragraph1],
        paragraph2: [this.formValue[0].paragraph2],
        paragraph3: [this.formValue[0].paragraph3],
        paragraph4: [this.formValue[0].paragraph4],
        paragraph5: [this.formValue[0].paragraph5],
        paragraph6: [this.formValue[0].paragraph6],
        paragraph7: [this.formValue[0].paragraph7],
        paragraph8: [this.formValue[0].paragraph8],
        paragraph9: [this.formValue[0].paragraph9],
        paragraph10: [this.formValue[0].paragraph10],
        paragraph11: [this.formValue[0].paragraph11],
        paragraph12: [this.formValue[0].paragraph12],
        paragraph13: [this.formValue[0].paragraph13],
        paragraph14: [this.formValue[0].paragraph14],
        paragraph15: [this.formValue[0].paragraph15],
        paragraph16: [this.formValue[0].paragraph16],
        paragraph17: [this.formValue[0].paragraph17],
        paragraph18: [this.formValue[0].paragraph18],
        paragraph19: [this.formValue[0].paragraph19],
        paragraph20: [this.formValue[0].paragraph20],
      });
      this.isLoaded = true;
    });
  }

  async onSubmit(input: PrivacyConsent): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      input.id = this.formValue[0].id;
      (await this.gestionService.setPrivacy(input)).subscribe((data) => {
        this.formGroup = this.formBuilder.group({
          paragraph1: [this.formGroup.value.paragraph1],
          paragraph2: [this.formGroup.value.paragraph2],
          paragraph3: [this.formGroup.value.paragraph3],
          paragraph4: [this.formGroup.value.paragraph4],
          paragraph5: [this.formGroup.value.paragraph5],
          paragraph6: [this.formGroup.value.paragraph6],
          paragraph7: [this.formGroup.value.paragraph7],
          paragraph8: [this.formGroup.value.paragraph8],
          paragraph9: [this.formGroup.value.paragraph9],
          paragraph10: [this.formGroup.value.paragraph10],
          paragraph11: [this.formGroup.value.paragraph11],
          paragraph12: [this.formGroup.value.paragraph12],
          paragraph13: [this.formGroup.value.paragraph13],
          paragraph14: [this.formGroup.value.paragraph14],
          paragraph15: [this.formGroup.value.paragraph15],
          paragraph16: [this.formGroup.value.paragraph16],
          paragraph17: [this.formGroup.value.paragraph17],
          paragraph18: [this.formGroup.value.paragraph18],
          paragraph19: [this.formGroup.value.paragraph19],
          paragraph20: [this.formGroup.value.paragraph20],
        });
        this.isSpinner = false;
        this.isSubmited = true;
        setTimeout(() => {
          this.isSubmited = false;
        }, 2000);
      });
    }
  }
}
