<div class="first" >
    <h1>Convenio Sectorial</h1>
    <div class="agreement-nav" fxLayout="row wrap">
        <div [ngClass]="agreementSelected === 1 ? 'active' : 'no-active'" fxFlex="25%" fxFlex.xs="50%" fxFlex.sm="25%"
            fxFlex.md="25%">
            <button class="nav-button" mat-button (click)="onNavButtonClick(1)">CONVENIO 1</button>
        </div>
        <div [ngClass]="agreementSelected === 2 ? 'active' : 'no-active'" fxFlex="25%" fxFlex.xs="50%" fxFlex.sm="25%"
            fxFlex.md="25%">
            <button class="nav-button" mat-button (click)="onNavButtonClick(2)">CONVENIO 2</button>
        </div>
        <div [ngClass]="agreementSelected === 3 ? 'active' : 'no-active'" fxFlex="25%" fxFlex.xs="50%" fxFlex.sm="25%"
            fxFlex.md="25%">
            <button class="nav-button" mat-button (click)="onNavButtonClick(3)">CONVENIO 3</button>
        </div>
        <div [ngClass]="agreementSelected === 4 ? 'active' : 'no-active'" fxFlex="25%" fxFlex.xs="50%" fxFlex.sm="25%"
            fxFlex.md="25%">
            <button class="nav-button" mat-button (click)="onNavButtonClick(4)">CONVENIO 4</button>
        </div>
    </div>

    <div *ngIf="isLoaded">
        <div *ngIf="agreementSelected === 1">
            <h4 class="agreement-title">{{agreements[0].title}} </h4>
            <div>
                <iframe #pdfFrame width="100%" height="100%"
                    style="min-height: 500px; border-radius: 6px; border-width: 1px;"></iframe>
            </div>
            <div class="linkList">
                <h4>Links externos</h4>
                <ul *ngIf="agreements[0].link1 !== '' || agreements[0].link2 !== ''">
                    <li *ngIf="agreements[0].link1 !== ''" class="linksLi">
                        <a class="links" target="_blank" href={{agreements[0].link1}}>{{agreements[0].link1}}</a>
                    </li>
                    <li *ngIf="agreements[0].link2 !== ''" class="linksLi">
                        <a class="links" target="_blank" href={{agreements[0].link2}}>{{agreements[0].link2}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="agreementSelected === 2">
            <h4 class="agreement-title">{{agreements[0].title}} </h4>
            <div>
                <iframe #pdfFrame width="100%" height="100%"
                    style="min-height: 500px; border-radius: 6px; border-width: 1px;"></iframe>
            </div>
            <div class="linkList">
                <h4>Links externos</h4>
                <ul *ngIf="agreements[0].link1 !== '' || agreements[0].link2 !== ''">
                    <li *ngIf="agreements[0].link1 !== ''" class="linksLi">
                        <a class="links" target="_blank" href={{agreements[0].link1}}>{{agreements[0].link1}}</a>
                    </li>
                    <li *ngIf="agreements[0].link2 !== ''" class="linksLi">
                        <a class="links" target="_blank" href={{agreements[0].link2}}>{{agreements[0].link2}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="agreementSelected === 3">
            <h4 class="agreement-title">{{agreements[0].title}} </h4>
            <div>
                <iframe #pdfFrame width="100%" height="100%"
                    style="min-height: 500px; border-radius: 6px; border-width: 1px;"></iframe>
            </div>
            <div class="linkList">
                <h4>Links externos</h4>
                <ul *ngIf="agreements[0].link1 !== '' || agreements[0].link2 !== ''">
                    <li *ngIf="agreements[0].link1 !== ''" class="linksLi">
                        <a class="links" target="_blank" href={{agreements[0].link1}}>{{agreements[0].link1}}</a>
                    </li>
                    <li *ngIf="agreements[0].link2 !== ''" class="linksLi">
                        <a class="links" target="_blank" href={{agreements[0].link2}}>{{agreements[0].link2}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="agreementSelected === 4">
            <h4 class="agreement-title">{{agreements[0].title}} </h4>
            <div>
                <iframe #pdfFrame width="100%" height="100%"
                    style="min-height: 500px; border-radius: 6px; border-width: 1px;"></iframe>
            </div>
            <div class="linkList">
                <h4>Links externos</h4>
                <ul *ngIf="agreements[0].link1 !== '' || agreements[0].link2 !== ''">
                    <li *ngIf="agreements[0].link1 !== ''" class="linksLi">
                        <a class="links" target="_blank" href={{agreements[0].link1}}>{{agreements[0].link1}}</a>
                    </li>
                    <li *ngIf="agreements[0].link2 !== ''" class="linksLi">
                        <a class="links" target="_blank" href={{agreements[0].link2}}>{{agreements[0].link2}}</a>
                    </li>
                </ul>
            </div>
        </div>

    </div>

</div>