<div class="buttonMenu">
  <button class="buttons" mat-button (click)="goTo(1)">CONVENIO 1</button>
  <button class="buttons" mat-button (click)="goTo(2)">CONVENIO 2</button>
  <button class="buttons" mat-button (click)="goTo(3)">CONVENIO 3</button>
  <button class="buttons" mat-button (click)="goTo(4)">CONVENIO 4</button>
</div>

<div>
  <div *ngIf="agreementSelected === 1">
    <app-form-agreement1></app-form-agreement1>
  </div>
  <div *ngIf="agreementSelected === 2">
    <app-form-agreement2></app-form-agreement2>
  </div>
  <div *ngIf="agreementSelected === 3">
    <app-form-agreement3></app-form-agreement3>
  </div>
  <div *ngIf="agreementSelected === 4">
    <app-form-agreement4></app-form-agreement4>
  </div>
</div>