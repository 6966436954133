<div *ngIf="isLoaded">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 1</mat-label>
                <textarea type="text" matInput formControlName="paragraph1" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph1')?.errors && formGroup.get('paragraph1')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 2</mat-label>
                <textarea type="text" matInput formControlName="paragraph2" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph2')?.errors && formGroup.get('paragraph2')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 3</mat-label>
                <textarea type="text" matInput formControlName="paragraph3" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph3</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph3')?.errors && formGroup.get('paragraph3')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 4</mat-label>
                <textarea type="text" matInput formControlName="paragraph4" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph4')?.errors && formGroup.get('paragraph4')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 5</mat-label>
                <textarea type="text" matInput formControlName="paragraph5" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph5</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph5')?.errors && formGroup.get('paragraph5')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 6</mat-label>
                <textarea type="text" matInput formControlName="paragraph6" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph6')?.errors && formGroup.get('paragraph6')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 7</mat-label>
                <textarea type="text" matInput formControlName="paragraph7" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph7</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph7')?.errors && formGroup.get('paragraph7')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 8</mat-label>
                <textarea type="text" matInput formControlName="paragraph8" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph8</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph8')?.errors && formGroup.get('paragraph8')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 8</mat-label>
                <textarea type="text" matInput formControlName="paragraph9" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph9')?.errors && formGroup.get('paragraph9')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 10</mat-label>
                <textarea type="text" matInput formControlName="paragraph10" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph10</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph10')?.errors && formGroup.get('paragraph10')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 11</mat-label>
                <textarea type="text" matInput formControlName="paragraph11" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph11')?.errors && formGroup.get('paragraph11')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 12</mat-label>
                <textarea type="text" matInput formControlName="paragraph12" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph12</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph12')?.errors && formGroup.get('paragraph12')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 13</mat-label>
                <textarea type="text" matInput formControlName="paragraph13" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph13')?.errors && formGroup.get('paragraph13')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 14</mat-label>
                <textarea type="text" matInput formControlName="paragraph14" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph14</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph14')?.errors && formGroup.get('paragraph14')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 15</mat-label>
                <textarea type="text" matInput formControlName="paragraph15" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph15')?.errors && formGroup.get('paragraph15')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 16</mat-label>
                <textarea type="text" matInput formControlName="paragraph16" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph16</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph16')?.errors && formGroup.get('paragraph16')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 17</mat-label>
                <textarea type="text" matInput formControlName="paragraph17" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph17')?.errors && formGroup.get('paragraph17')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 18</mat-label>
                <textarea type="text" matInput formControlName="paragraph18" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph18</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph18')?.errors && formGroup.get('paragraph18')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 19</mat-label>
                <textarea type="text" matInput formControlName="paragraph19" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph19')?.errors && formGroup.get('paragraph19')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 20</mat-label>
                <textarea type="text" matInput formControlName="paragraph20" class="textarea-width"></textarea>
                <!-- <mat-icon matSuffix>paragraph20</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph20')?.errors && formGroup.get('paragraph20')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <div class="action">
            <div class="button">
                <button class="send" type="submit" mat-button color="success">
                    Actualizar
                </button>
            </div>
            <div class="spinner">
                <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
            </div>
            <div class="spinner" *ngIf="isSubmited">
                <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
                    <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
                  </g>
                </svg>
              </div>
        </div>
    </form>
</div>