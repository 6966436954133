import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { About, GestionService } from 'src/app/_services/gestion.service';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
})
export class AboutUsComponent implements OnInit, AfterViewInit {
  images = [
    { src: '../../../assets/about.png' },
    { src: '../../../assets/about2.png' },
    { src: '../../../assets/about3.png' },
    { src: '../../../assets/about4.png' },
  ];
  currentSlide = 0;
  autoSlide: boolean = true;
  isLoaded: boolean = false;
  slideInterval: number = 4500;
  screenWidth: number = 0;
  translateX: number = 0;
  imageIndex: number = 0;
  translateHtml: string = '';
  counter: number = 0;
  aboutInfo: About[] = [];

  constructor(private gestionService: GestionService) {}

  ngOnInit(): void {
    this.getScreenSize();
    this.setCounter();
    this.getAboutInfo();
  }

  ngAfterViewInit(): void {
    if (this.autoSlide) {
      this.autoSlideImages();
    }
  }

  onNextClick(): void {
    if (this.imageIndex === this.images.length - 1) {
      this.imageIndex = 0;
      this.translateX = 0;
    } else {
      this.imageIndex = this.imageIndex + 1;
      this.translateX = this.translateX - document.body.clientWidth;
    }
    this.translateHtml = `translateX(${this.translateX}px)`;
  }

  onPreviousClick(): void {
    if (this.imageIndex === 0) {
      this.imageIndex = this.images.length - 1;
      this.translateX = -(3 * document.body.clientWidth);
    } else {
      this.imageIndex = this.imageIndex - 1;
      this.translateX = this.translateX + document.body.clientWidth;
    }
    this.translateHtml = `translateX(${this.translateX}px)`;
  }

  private autoSlideImages(): void {
    setInterval(() => {
      this.onNextClick();
    }, this.slideInterval);
  }

  private async setCounter() {
    while (this.counter < 250) {
      this.counter = this.counter + 1;
      await new Promise((f) => setTimeout(f, 15));
    }
  }

  private async getAboutInfo(): Promise<void> {
    (await this.gestionService.getAbout()).subscribe((data) => {
      this.aboutInfo = data;
      this.isLoaded = true;
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = document.body.clientWidth;
  }
}
