import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { EmailService } from 'src/app/_services/email.service';
import { UsersService } from 'src/app/_services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';

interface RegisterFormInput {
  name: string;
  email: string;
  phone: string;
  password: string;
  repeatPassword: string;
  company: string;
  country: string;
  linkedin: string;
  license: [string];
  licenceType: [string];
  licenceNumber: string;
  licenceCustom?: string;
  conditions?: boolean;
  privacy?: boolean;
}

interface License {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-membership-registration',
  templateUrl: './membership-registration.component.html',
  styleUrls: ['./membership-registration.component.css'],
})
export class MembershipRegistrationComponent implements OnInit {
  isSpinner: boolean = false;
  otherDiv: boolean = false;

  selectedValue: string = '';
  formGroup!: UntypedFormGroup;
  licenseType: License[] = [
    { value: 'Ayudante', viewValue: 'Ayudante' },
    { value: 'Mecánico', viewValue: 'Mecánico' },
    { value: 'Estudiante', viewValue: 'Estudiante' },
    { value: 'Cat A', viewValue: 'Cat A' },
    { value: 'B1.1', viewValue: 'B1.1' },
    { value: 'B1.2', viewValue: 'B1.2' },
    { value: 'B1.3', viewValue: 'B1.3' },
    { value: 'B1.4', viewValue: 'B1.4' },
    { value: 'B2', viewValue: 'B2' },
    { value: 'C', viewValue: 'C' },
    { value: 'Otro', viewValue: 'Otro' },
  ];
  licenseValuesArray: Array<string> = [];

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private userService: UsersService,
    private emailService: EmailService
  ) {}

  async ngOnInit(): Promise<void> {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      repeatPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          (c: AbstractControl) => this.checkPasswords(c),
        ],
      ],
      company: [''],
      country: [''],
      license: ['', Validators.required],
      linkedin: [''],
      conditions: ['', Validators.required],
      privacy: ['', Validators.required],
      licenceNumber: ['', Validators.required],
      licenceCustom: [''],
    });
  }

  checkPasswords(control: AbstractControl): ValidationErrors | null {
    let pass = (document.getElementById('pass') as HTMLInputElement).value;
    let confirmPass = (
      document.getElementById('repeatPassword') as HTMLInputElement
    ).value;
    return pass === confirmPass ? null : { notSame: true };
  }

  get registerFormControl() {
    return this.formGroup.controls;
  }

  getPassword(): string {
    return (document.getElementById('pass') as HTMLInputElement).value;
  }

  async onSelectionChange(event: any): Promise<void> {
    await this.checkLicenseArray(event.source.value);
    await this.checkLicense();

    event.source.value === 'Otro' ? (this.otherDiv = !this.otherDiv) : '';
  }

  conditionClick(): void {
    window.open(`${environment.basePath}member-consent`);
  }

  privacyClick(): void {
    window.open(`${environment.basePath}privacy-statement`);
  }

  async onSubmit(input: RegisterFormInput): Promise<void> {
    if (this.formGroup.valid) {
      let licenseTypeAux: string = '';
      this.licenseValuesArray.forEach((value) => {
        licenseTypeAux = licenseTypeAux + '-' + value;
      });

      // input.license
      this.isSpinner = true;
      const user = {
        name: input.name,
        email: input.email,
        password: input.password,
        linkedin: input.linkedin,
        n_id: 'id' + input.name,
        phone: input.phone,
        business: input.company,
        country: input.country,
        licenseType: licenseTypeAux,
        licenseNumber: input.licenceNumber,
        licenseCustom: input.licenceCustom,
        validate: 0,
        lostPassword: 0,
      };

      try {
        (await this.userService.addUser(user)).subscribe(async (data) => {
          (await this.emailService.registerMail(user.email)).subscribe(
            (data) => {
              if (data === 'OK') {
                this.isSpinner = false;
                Swal.fire({
                  title: '¡Gracias por realizar el registro con nosotros!',
                  text: 'Se ha enviado un correo electrónico a la dirección adjuntada en el formulario de registro el cual contiene las instrucciones a seguir para poder validarle en la web.',
                  footer: 'Comprobar carpeta de correo no deseado',
                  icon: 'success',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#011d35',
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.router.navigate(['/home']);
                  }
                });
              } else {
                // Swal.fire({
                //   title: 'Se ha producido un error inesperado',
                //   text: 'Inténtelo de nuevo más tarde.',
                //   icon: 'error',
                //   confirmButtonText: 'OK',
                //   confirmButtonColor: '#011d35',
                // }).then((result) => {
                //   this.formGroup.reset();
                // });
              }
            }
          );
        });
      } catch {
        Swal.fire({
          title: 'No se ha podido completar tu registro',
          text: '¡Inténtalo de nuevo más tarde!',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#011d35',
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/home']);
          }
        });
      }
    }
  }

  private async checkLicense() {
    this.formGroup.controls['licenceNumber'].enable();
    let stringAux = '';
    let check = 0;

    this.licenseValuesArray.forEach((value) => {
      stringAux = stringAux + '-' + value;
    });

    if (
      stringAux.includes('Estudiante') ||
      stringAux.includes('Ayudante') ||
      stringAux.includes('Mecánico') ||
      stringAux.includes('Otro')
    ) {
      check = 1;
    }
    if (
      stringAux.includes('Cat A') ||
      stringAux.includes('B1.1') ||
      stringAux.includes('B1.2') ||
      stringAux.includes('B1.3') ||
      stringAux.includes('B1.4') ||
      stringAux.includes('B2') ||
      stringAux.includes('C')
    ) {
      if (check === 1) {
        this.formGroup.patchValue({
          licenceNumber: '',
        });
      }
      check = 2;
    }
    if (check === 0 || check === 2) {
      this.formGroup.patchValue({
        licenceNumber: (document.getElementById('num-lic') as HTMLInputElement)
          .value,
      });
    }
    if (check === 1) {
      this.formGroup.patchValue({
        licenceNumber: 'No aplica',
      });
      this.formGroup.controls['licenceNumber'].disable();
    }
    if (this.licenseValuesArray.length === 0) {
      this.formGroup.patchValue({
        licenceNumber: '',
      });
    }
  }

  private async checkLicenseArray(selection: string): Promise<void> {
    const oldLength = this.licenseValuesArray.length;
    if (this.licenseValuesArray.length === 0) {
      this.licenseValuesArray.push(selection);
    } else {
      this.licenseValuesArray = this.licenseValuesArray.filter(
        (item) => item !== selection
      );
    }
    if (oldLength === this.licenseValuesArray.length)
      this.licenseValuesArray.push(selection);
  }
}
